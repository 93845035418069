import React from "react";
import Spinner from "../components/Spinner";

export function promised<T>(promise: Promise<T>, cb: (value: T) => any = value => value) {
    return <Promised
        promise={promise}
        cb={cb}
    />
}

class Promised extends React.Component<{ promise: Promise<any>, cb: any }> {
    promise?: Promise<any>
    state = { loading: true, value: null }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render() {
        if (this.promise !== this.props.promise) {
            this.promise = this.props.promise
            this.promise.then(value => {
                this.setState({ loading: false, value })
            })
        }
        return this.state.loading ? <Spinner /> : this.props.cb(this.state.value);
    }
}