import App from "./App";
import { ga } from "./tracking";

export default function windowEvents(
    window: Window & { app?: App, _eventHandlersSet?: boolean }
) {
    if (window._eventHandlersSet) {
        return;
    }

    window._eventHandlersSet = true;

    window.addEventListener('click', e => {
        if (!window.app) {
            return
        }

        var elm: HTMLElement | null = (e.target || e.srcElement) as HTMLElement;

        while (elm) {
            // iterate elements going up the tree to see if this belongs to any hyperlink

            if (isLink(elm)) {
                let { href, target } = elm;
                href = href.replace(document.location.protocol + '//' + document.location.host, '')

                if (href[0] === '/' && !target && !elm['download']) {
                    // route local links through app redirect handler:
                    e.preventDefault();
                    window.history.pushState({}, window.document.title, href);
                    window.app.setLocation(href)
                    return false
                } else if (href.startsWith('http') && !elm['download']) {
                    // log outbound links to google analytics:

                    if (target) {
                        // target set means page opens in new tab:
                        ga('send', 'event', 'outbound', 'click', href)
                        return;
                    } else {
                        // Leaving the page will stop javascript execution and
                        // analytics might not be logged. So override the default
                        // handler and manually assign location after GA record is done.
                        window.app.loading = true;
                        ga('send', 'event', 'outbound', 'click', href, {
                            transport: 'beacon',
                            hitCallback() {
                                document.location.assign(href);
                            }
                        })
                        e.preventDefault()
                        return false
                    }
                }
            }

            elm = (<HTMLElement>elm).parentElement;
        }
    });

    window.addEventListener('popstate', e => {
        if (!window.app) { return }

        let { document } = window;
        window.app.setLocation(document.location.pathname + document.location.search);
        window.scrollTo(0, 0)
    });
}

function isLink(tag: HTMLElement): tag is HTMLLinkElement {
    return tag.tagName === 'A'
}