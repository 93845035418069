import React from 'react';
import { Icon, ICON_FB_STR, ICON_GOOGLE, ICON_LINKDIN } from '../Icons';
import { FB_CLIENT_ID, GOOGLE_CLIENT_ID, LINKEDIN_CLIENT_ID, WEBAPP_URL } from '../../config';
import App from '../../App';

export default ({ app }: { app: App }) =>
    <>
        {FB_CLIENT_ID && <div className="col-12 col-md-6 text-uppercase text-white">
            <a className="text-center social-btn fb py-2 mb-3 d-flex justify-content-center align-items-center"
                href="javascript:void(0)"
                onClick={app.facebookLogin}
            >
                <Icon name={ICON_FB_STR} width={20} height={20} color="#ffffff" />
                <span className="pl-3">facebook</span>
            </a>
        </div>}
        {GOOGLE_CLIENT_ID && <div className="col-12 col-md-6 text-uppercase text-white">
            <a className="text-center social-btn google py-2 mb-3 d-flex justify-content-center align-items-center"
                href="javascript:void(0)"
                onClick={app.googleLogin}>
                <Icon name={ICON_GOOGLE} width={20} height={20} color="#ffffff" />
                <span className="pl-3">google+</span>
            </a>
        </div>}
        {LINKEDIN_CLIENT_ID && <div className="col-12 col-md-6 ml-auto mr-auto text-uppercase text-white">
            <a className="text-center social-btn linkedin py-2 mb-3 d-flex justify-content-center align-items-center"
                href="javascript:void(0)"
                onClick={app.linkedinLogin}>
                <Icon name={ICON_LINKDIN} width={20} height={20} color="#ffffff" />
                <span className="pl-3">linkedin</span>
            </a>
        </div>}
    </>

// function fbLoginUrl(app: App) {
//     return `https://www.facebook.com/v2.12/dialog/oauth?` +
//         `app_id=${FB_CLIENT_ID}&` +
//         `redirect_uri=${encodeURIComponent(WEBAPP_URL + '/login/facebook')}&` +
//         `response_type=code&` +
//         `scope=email&` +
//         `state=${encodeURIComponent()}&` +
//         `version=v2.12`
// }

// function googleLoginUrl(app: App) {
//     return `https://accounts.google.com/o/oauth2/v2/auth?` +
//         `client_id=${GOOGLE_CLIENT_ID}&` +
//         `redirect_uri=${encodeURIComponent(WEBAPP_URL + '/login/google')}&` +
//         `response_type=code&` +
//         `scope=email&` +
//         `state=${encodeURIComponent(app.createOauthState())}&` +
//         `version=v2.12`
// }

// function linkedinLoginUrl(app: App) {
//     return `https://www.linkedin.com/oauth/v2/authorization?` +
//         `response_type=code&` +
//         `client_id=${LINKEDIN_CLIENT_ID}&` +
//         `scope=r_basicprofile%20r_emailaddress&` +
//         `redirect_uri=${encodeURIComponent(WEBAPP_URL + '/login/linkedin')}&` +
//         `state=${encodeURIComponent(app.createOauthState())}`
// }