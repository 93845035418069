import React from "react";
import App, { AppContext } from './App';
import { observer } from "mobx-react";
import Spinner from "./components/Spinner";
import MainLayout from "./components/layout/MainLayout";
import "./assets/scss/app.scss";

@observer
export default class AppContainer extends React.Component<{ window?: Window, app: App }> {
    screenSize = 400
    componentDidUpdate() {
        if (this.props.window) {
            this.screenSize = this.props.window.screen.height
            this.props.window.scrollTo(0, 0); // scroll to top after page update
        }
    }

    render() {
        return <AppContext.Provider value={this.props.app}>
            {this.props.app.react.rootElement ||
                <MainLayout>
                    <div className="container my-5" style={{ height: (this.screenSize - 100) + 'px' }}>
                        <div className="py-5">
                            <Spinner size={100} />
                        </div>
                    </div>
                </MainLayout>
            }
        </AppContext.Provider>
    }
}
