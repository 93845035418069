export function debounce<T extends () => Promise<any>>(delay: number, func: T): T {
    let timeout, promise, resolve, reject;
    promise = new Promise((res, rej) => {
        resolve = res
        rej = reject
    })
    return <any>function (this: any) {
        let context = this;
        let args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            try {
                resolve(func.apply(context, args))
            } catch (e) {
                reject(e)
            } finally {
                promise = new Promise((res, rej) => {
                    resolve = res
                    reject = rej
                })
            }
        })
        return promise
    }
}

export function cmpKey(key: symbol | string | ((val) => string), direction: 'ASC' | 'DESC' = 'ASC') {
    let dir = direction == 'DESC' ? -1 : 1
    if (typeof key === 'function') {
        return function (a, b) {
            let va = key(a), vb = key(b)
            return dir * (va < vb ? -1 : (va > vb ? 1 : 0));
        }
    } else {
        return function (a, b) {
            return dir * (a[key] < b[key] ? -1 : (a[key] > b[key] ? 1 : 0));
        }
    }
}

export function chain(...f: (() => void)[]): () => void {
    return function () {
        f.forEach(f => f())
    }
}

export function sortBy<T>(collection: T[], key: keyof T, direction: 'ASC' | 'DESC'): T[] {
    let sortedCollection = Array.from(collection)
    sortedCollection.sort(cmpKey(key as string, direction))
    return sortedCollection
}

export function shallowEq<T>(a:T, b: T): boolean {
    if (Object.keys(a).length !== Object.keys(b).length) {
        return false
    }
    for (const key of Object.keys(a)) {
        if (a[key] !== b[key]) {
            return false
        }
    }
    return true
}