import React from 'react';

export const ICON_EVENT = 'icon-event';
export const ICON_ACCOUNT = "icon-account";
export const ICON_CLOSE = "icon-close";
export const ICON_HOME = "icon-home";
export const ICON_PHONE = "icon-phone";
export const ICON_MENU = "icon-menu";
export const ICON_SEARCH = "icon-search";
export const ICON_STAR = "icon-star";
export const ICON_DONE = "icon-done";
export const ICON_MAIL = "icon-mail";
export const ICON_FB = "icon-fb";
export const ICON_WETOGETHER = "icon-wetogether";
export const ICON_TERM = "icon-term";
export const ICON_TEAM = "icon-team";
export const ICON_LOCK = "icon-lock";
export const ICON_FB_STR = 'icon-fb-str';
export const ICON_GOOGLE = 'icon-google';
export const ICON_PERSON = 'icon-person';
export const ICON_LINKDIN = 'icon-linkdin';
export const ICON_TWITTER = 'icon-twitter';
export const ICON_GITHUB = 'icon-github';
export const ICON_LOCATION = 'icon-location';
export const ICON_HOST = 'icon-host';
export const ICON_COMMENT = 'icon-comment';
export const ICON_BRUSH = 'icon-brush';
export const ICON_LIGHT = 'icon-light';
export const ICON_WORK = 'icon-work';
export const ICON_SCHOOL = 'icon-school';
export const ICON_INTEREST = 'icon-interest';
export const ICON_MONEY = 'icon-money';
export const ICON_EDIT = 'icon-edit';
export const ICON_IMG = 'icon-img';
export const ICON_PENCIL = 'icon-pencil';
export const ICON_PHOTO_ADD = 'icon-photo-add';
export const ICON_DELETE = 'icon-delete';
export const ICON_CREATE = 'icon-reate';
export const ICON_CHECK = 'icon-check';
export const ICON_BLOCK = 'icon-block';
export const ICON_KEYBOARD_ARROW_RIGHT = 'icon-keyboard-arrow-right';

const default_fill = {
  [ICON_TWITTER]: '#30ccfd',
  [ICON_CHECK]: '#66cc66',
  other: '#b1c5ce'
}

export function Icon({
  name = ICON_EVENT,
  width = null as null | number | string,
  height = null as null | number | string,
  size = null as null | number,
  color = null as null | string,
  href = null as null | string,
  linkTarget = "_self",
  className = ""
}) {
  let svg = <span className={"icon-container " + name}>
      <svg fill={color || default_fill[name] || default_fill.other} style={{width: width || 24, height: height || 24}} className={className}>
          <use xlinkHref={'#' + name}></use>
      </svg>
    </span>

    if(!href) { return svg }
    return <a href={href} target={linkTarget}>{svg}</a>
}

export function rawIcon(raw: string, {size=null as null | number}={}) {
  return <span className="raw-icon"
    style={{fontSize: size ? size + 'px' : 'inherit'}}
    dangerouslySetInnerHTML={{ __html: raw }}></span>
}

export class IconDefs extends React.Component {
    render() {
        return <svg style={{position: 'absolute', width: 0, height: 0}} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>

          <symbol id={ICON_KEYBOARD_ARROW_RIGHT} viewBox="0 0 24 24">
            <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"/>
            <path fill="none" d="M0,0h24v24H0V0z"/>
          </symbol>

          <symbol id={ICON_BLOCK} viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"/>
          </symbol>

          <symbol id={ICON_CHECK} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
          </symbol>

          <symbol id={ICON_DELETE} viewBox="0 0 24 24">
            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
            <path d="M0 0h24v24H0z" fill="none" />
          </symbol>

          <symbol id={ICON_PHOTO_ADD} viewBox="0 0 24 24">
            <path d="M3 4V1h2v3h3v2H5v3H3V6H0V4h3zm3 6V7h3V4h7l1.83 2H21c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V10h3zm7 9c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-3.2-5c0 1.77 1.43 3.2 3.2 3.2s3.2-1.43 3.2-3.2-1.43-3.2-3.2-3.2-3.2 1.43-3.2 3.2z"/>
          </symbol>

          <symbol id={ICON_PENCIL} viewBox="0 0 8 8">
            <path d="M6 0l-1 1 2 2 1-1-2-2zm-2 2l-4 4v2h2l4-4-2-2z" />
          </symbol>

          <symbol id={ICON_EVENT} viewBox="0 0 24 24">
            <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_ACCOUNT} viewBox="0 0 24 24">
            <path d="M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6v-1z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_CLOSE} viewBox="0 0 24 24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_HOME} viewBox="0 0 24 24">
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_PHONE} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"/>
          </symbol>

          <symbol id={ICON_MENU} viewBox="0 0 44 44">
            <path d="M0 0h44v44H0z"/>
            <path fill="#87898A" d="M10 30h24v-2.667H10V30zm0-6.667h24v-2.666H10v2.666zm0-6.666h24V14H10v2.667z"/>
          </symbol>

          <symbol id={ICON_SEARCH} viewBox="0 0 24 24">
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_STAR} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/>
          </symbol>

          <symbol id={ICON_DONE} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
          </symbol>

          <symbol id={ICON_MAIL} viewBox="0 0 24 24">
            <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_FB} viewBox="0 0 44 44">
            <g fill="none" fillRule="evenodd">
              <path fill="#3B5998" d="M0 22C0 9.85 9.85 0 22 0s22 9.85 22 22-9.85 22-22 22S0 34.15 0 22z"/>
              <path fill="#FFF" d="M24.31 37.004V22h4.141L29 16.83h-4.69l.006-2.588c0-1.348.129-2.071 2.065-2.071h2.59V7h-4.143c-4.975 0-6.727 2.508-6.727 6.726v3.105H15V22h3.101v15.003h6.208z"/>
            </g>
          </symbol>

          <symbol id={ICON_WETOGETHER} viewBox="0 0 152 152">
            <g>
              <path fill="#656565" d="M39.1,142.5c-0.1-17.3-1.4-34.6-3.1-51.9c-1.6-16.6,0.1-33,1.6-50.4c2.9,2.3,5.1,4,7.9,4.3
                c4-0.8,3.2,3.5,5.2,4.9c0.8,0.9,1.7,1.8,2.4,2.8c5,7,10.9,12.7,19.1,15.8c1.6,0.6,2.9,1.9,4.4,2.9c0.9,1.9,0.8,3.5-1.5,4.3
                c-2.5-1.9-5.7-2.5-8.6-3.2c-4.3-1-7.7-3.7-11.6-5.4c-2.5,1.9-0.6,4.1-0.5,6.2c0.3,4.5,2.6,10.1,0.7,13.4c-2.2,3.8-2.5,7.4-2.8,11.2
                c-1.1,14-1.9,28-2.8,42C45.7,139.5,42.1,139.8,39.1,142.5z"/>
              <path fill="#006598" d="M88.7,73.2c11.5-1.8,17.5-8.4,17.1-20.3c1.7-2.3,1.1-5.5,3.1-7.6c1.5-0.9,3.1-1.7,4.4-2.9
                c1.3-1.1,2.3-2.6,3.9-4.4c8.7,34,0.9,67.6,0.1,101.1c-3.3,1.4-6.5,1.1-9.5-0.8c-1.1-13.8-2.1-27.5-3.2-41.3
                c-0.3-3.6,0.5-7.3-2.8-10.6c-1.8-1.9-0.1-6,0.2-9.1c1.3-3,5.2-2.8,6.7-5.5c1.4-2.6,3-5.1,4.1-7.9c-2.3,8.9-8.7,12.4-17.1,13.1
                c-2.5,0.2-5.3,1-7.3-1.6C88.1,74.5,88.1,73.8,88.7,73.2z"/>
              <path fill="#FACC94" d="M50.8,49.4c-0.5,2.4-1.3,1.6-1.7,0.1c-0.6-2.1-1-4.3-3.5-4.9c2.2,0,3.1-1.2,2.8-3.3c0.1-0.1,0.3-0.4,0.3-0.4
                c0.5,0.2,0.7-0.2,0.4-0.3c-2.5-1.1-4.9-2.1-6.8-4.3c-1.2-1.4-2.7-2.6-2.8-4.7c0.4-1.7,0.3-3.5,0.5-5.2c0.2-1.8,1.2-3.2,3.1-2.8
                c3.7,0.8,5.1-1.6,6.3-4.2c1.3-2.8,3.4-4,6.4-3.1c3.1,0.9,5.4-0.4,7.5-2.6c3.3,4.2,3.7,10.3,3,14.2c-0.9,5.1-4,12-10.6,13
                C49.6,41.9,50.3,45.3,50.8,49.4z"/>
              <path fill="#5E3213" d="M87.7,20.1c-0.8-0.4-1.8-0.8-1.4-1.9c3.9-10.7,13.8-15.6,23.5-11.5c9.2,3.8,11.8,13.4,6.7,24
                c-0.2,0.3-0.4,0.6-0.6,0.9c-1.2,1.2-2.4,0.5-3.5-0.2c-1.9-1.4-1.2-3.5-1.4-5.8c-3.8,2.2-5.8-0.2-7.2-2.8c-1.9-3.5-3.9-4.8-7.8-2.6
                C93.5,21.7,90.5,22.1,87.7,20.1z"/>
              <path fill="#F9CC94" d="M87.7,20.1c3.2,0.1,6.7,0.5,9.1-1.6c4.2-3.5,5.5-0.9,7.4,2.4c1.2,2.1,1.7,7.4,6.5,2.8c1.4-1.3,2.1,0.7,2.2,2.3
                c0.1,1.6-2.2,3.2-0.1,4.8c1.5,3.8-2.2,4.8-3.8,6.9c-0.9,1.2-3.1,0.6-3.5,2.6c-5.9,2.2-10,1.1-13.6-4C88.6,31.3,86,26.3,87.7,20.1z"
                />
              <path fill="#B07C38" d="M63.4,13.7c-0.3,5-3.8,5.2-7.1,4c-3.3-1.2-4.5-0.1-6,2.6c-1.5,2.7-2.1,7.6-7.6,4.2c-1.6-1-1.8,1.5-1.7,2.8
                c0.2,1.7,1.3,3.4-0.9,4.5c-0.8,0.6-1.6,0.5-2.4,0.1c-3.7-6.1-4.9-12.5-0.7-18.8c3.7-5.6,9.5-7.6,16-6.9C57.9,6.8,62,8.5,63.4,13.7z
                "/>
              <path fill="#5B2B1F" d="M107.8,138.4c3.2,0.3,6.4,0.5,9.5,0.8c1.1,6.8,1.1,6.5-5.3,6.9c-3.7,0.2-7.3,0.8-10.9,0.2
                c-1.4-0.2-3.4-0.4-3.4-2.5c0-1.7,1.6-2,3-2.5C103.1,140.4,106,140.6,107.8,138.4z"/>
              <path fill="#343434" d="M39.1,142.5c2-4.8,4.1-5.5,10.3-3.1c2.5,0.7,5,1.4,7.5,2.2c1.1,0.4,2.4,1,2.2,2.5c-0.1,1.2-1.2,1.5-2.2,1.9
                c-5.4,1.8-10.9-0.3-16.4,0.4C38.6,146.6,39.2,144,39.1,142.5z"/>
              <path fill="#F7CB94" d="M88.7,73.2c0.1,0.6,0.1,1.2,0.2,1.8c0.3,1.3,1.5,2.4,0.8,3.9c-5.6,1.8-10.9,2.1-14.9-3.2
                c-0.2-0.3-0.2-0.6,0.1-0.9c1.4-0.9,1.1-2.6,1.7-3.9C81.1,69.6,84.5,73.6,88.7,73.2z"/>
              <path fill="#0A5982" d="M89.7,78.9c-0.3-1.3-0.5-2.6-0.8-3.9c8.2,2.7,16.7-0.3,20.8-7.3c1.4-2.4,2.9-4.6,4.7-6.7
                c-2.3,6.8-3.9,14.1-12.4,16.3C97.8,77,93.8,78.6,89.7,78.9z"/>
              <path fill="#DFAF74" d="M105.6,40.1c-2-0.3-1.3-0.8-0.1-1.5c3.2-1.9,6.4-4,7.5-7.9c1,0.3,2,0.6,3,0.9C115.6,38,113.5,39.8,105.6,40.1
                z"/>
              <path fill="#5E5E5D" d="M74.9,74.8c-0.1,0.3-0.1,0.6-0.1,0.9C68,73,60,72.9,54.8,66.7c0.4-0.3,1-0.9,1.1-0.9
                c5.4,4.2,12.1,5.5,18.4,7.6C74.7,73.5,74.7,74.3,74.9,74.8z"/>
              <path fill="#DEAF73" d="M37.8,31.9c0.8,0,1.6-0.1,2.4-0.1c3.1,3.9,6.2,7.9,13.1,9c-2.6,0.3-3.7,0.4-4.9,0.5c-1-0.3-1.9-0.8-2.9-0.9
                c-4-0.3-7.1-1.3-6.5-6.3C39.1,33.4,38.2,32.6,37.8,31.9z"/>
              <path fill="#DFB95E" d="M108.9,45.3c-0.4,2.8-0.4,5.7-3.1,7.6C107,50.4,104.3,46.4,108.9,45.3z"/>
              <path fill="#595959" d="M44.6,88.8c-1.6,0.4-3.2,1.7-5.4-0.1c3.6-1.4,2.6-3.8,1.5-6.2C36.9,74,36.2,65.2,37.6,56
                c1.1,9,1.8,18,5.8,26.3c0.9,1.9,1.3,4,3.1,5.3C46.1,88.5,45.5,89,44.6,88.8z"/>
              <path fill="#EBC18E" d="M44.6,88.8c0.6-0.4,1.3-0.8,1.9-1.1c4.9,2.4,5,6.1,0,12.4C44.7,96.3,46.1,92.3,44.6,88.8z"/>
            </g>
          </symbol>

          <symbol id={ICON_TERM} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/>
          </symbol>

          <symbol id={ICON_LOCK} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/>
          </symbol>

          <symbol id={ICON_FB_STR} viewBox="0 0 310 310">
            <path id="XMLID_835_" d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064
              c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996
              V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545
              C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703
              c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"/>
          </symbol>

          <symbol id={ICON_GOOGLE} viewBox="0 0 512 512">
          <g>
            <polygon points="448,224 448,160 416,160 416,224 352,224 352,256 416,256 416,320 448,320 448,256 512,256 512,224 		"/>
          </g>
          <g>
            <path d="M160,224v64h90.528c-13.216,37.248-48.8,64-90.528,64c-52.928,0-96-43.072-96-96c0-52.928,43.072-96,96-96
              c22.944,0,45.024,8.224,62.176,23.168l42.048-48.256C235.424,109.824,198.432,96,160,96C71.776,96,0,167.776,0,256
              s71.776,160,160,160s160-71.776,160-160v-32H160z"/>
          </g>
          </symbol>

          <symbol id={ICON_PERSON} viewBox="0 0 24 24">
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_LINKDIN} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
            <path d="M19 3a2 2 0 0 1 2 2v14c0 1.104-.896 2-2 2H5a2 2 0 0 1-2-2V5c0-1.105.885-2 1.99-2H19zm-.507 15.492v-5.297a3.252 3.252 0 0 0-3.252-3.252c-.85 0-1.842.522-2.323 1.3V10.13H10.13v8.363h2.788v-4.925a1.394 1.394 0 0 1 2.787 0v4.925h2.788zM6.878 8.56a1.684 1.684 0 1 0 0-3.37 1.684 1.684 0 0 0 0 3.37zm1.393 9.932v-8.364H5.484v8.364H8.27z"/>
          </symbol>

          <symbol id={ICON_TWITTER} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
            <path d="M22.46 6.012a8.58 8.58 0 0 1-2.466.676 4.305 4.305 0 0 0 1.887-2.374 8.591 8.591 0 0 1-2.725 1.041 4.292 4.292 0 0 0-7.313 3.914 12.184 12.184 0 0 1-8.846-4.483 4.271 4.271 0 0 0-.581 2.157 4.29 4.29 0 0 0 1.91 3.572 4.276 4.276 0 0 1-1.945-.537v.054c0 2.08 1.48 3.814 3.443 4.209a4.296 4.296 0 0 1-1.939.073 4.296 4.296 0 0 0 4.01 2.98 8.61 8.61 0 0 1-5.33 1.838c-.347 0-.688-.02-1.024-.06A12.147 12.147 0 0 0 8.12 21c7.893 0 12.21-6.54 12.21-12.21 0-.187-.004-.372-.012-.556a8.719 8.719 0 0 0 2.141-2.222z"/>
          </symbol>

          <symbol id={ICON_GITHUB} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
            <path fillRule="evenodd" d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4.438c-.321-.073-.33-.68-.33-.894l.01-2.469c0-.84-.288-1.389-.61-1.666 2.004-.223 4.109-.984 4.109-4.441 0-.983-.348-1.786-.925-2.415.092-.228.401-1.143-.09-2.382 0 0-.754-.242-2.473.922A8.63 8.63 0 0 0 12 7.352a8.62 8.62 0 0 0-2.253.303c-1.72-1.164-2.474-.922-2.474-.922-.49 1.239-.182 2.154-.089 2.381a3.479 3.479 0 0 0-.926 2.415c0 3.45 2.1 4.222 4.1 4.449-.258.225-.49.621-.572 1.203-.513.23-1.817.627-2.62-.748 0 0-.475-.864-1.378-.928 0 0-.88-.01-.062.548 0 0 .59.276 1 1.316 0 0 .528 1.75 3.031 1.207l.012 1.53c0 .213-.015.825-.34.894H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z"/>
          </symbol>

          <symbol id={ICON_TEAM} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
          </symbol>

          <symbol id={ICON_LOCATION} viewBox="0 0 24 24">
            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_HOST} viewBox="0 0 24 24">
            <path d="M15 11V5l-3-3-3 3v2H3v14h18V11h-6zm-8 8H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_COMMENT} viewBox="0 0 24 24">
            <path d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_BRUSH} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M7 14c-1.66 0-3 1.34-3 3 0 1.31-1.16 2-2 2 .92 1.22 2.49 2 4 2 2.21 0 4-1.79 4-4 0-1.66-1.34-3-3-3zm13.71-9.37l-1.34-1.34c-.39-.39-1.02-.39-1.41 0L9 12.25 11.75 15l8.96-8.96c.39-.39.39-1.02 0-1.41z"/>
          </symbol>

          <symbol id={ICON_LIGHT} viewBox="0 0 24 24">
              <defs>
                  <path d="M0 0h24v24H0V0z" id="a"/>
              </defs>
              <clipPath id="b">
                  <use overflow="visible" xlinkHref="#a"/>
              </clipPath>
              <path clipPath="url(#b)" d="M6 14l3 3v5h6v-5l3-3V9H6zm5-12h2v3h-2zM3.5 5.875L4.914 4.46l2.12 2.122L5.62 7.997zm13.46.71l2.123-2.12 1.414 1.414L18.375 8z"/>
          </symbol>

          <symbol id={ICON_WORK} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"/>
          </symbol>

          <symbol id={ICON_INTEREST} viewBox="0 0 24 24">
            <path d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z"/>
            <path d="M0 0h24v24H0z" fill="none" />
          </symbol>

          <symbol id={ICON_SCHOOL} viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"/>
          </symbol>

          <symbol id={ICON_MONEY} viewBox="0 0 24 24">
            <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_EDIT} viewBox="0 0 24 24">
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_IMG} viewBox="0 0 24 24">
            <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

          <symbol id={ICON_CREATE} viewBox="0 0 24 24">
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </symbol>

        </defs>
      </svg>
    }
}