import React from "react";
import MainLayout from "../layout/MainLayout";
import { UserDetails } from "../../types";
import Spinner from "../Spinner";
import { observer } from "mobx-react";
import { Col, Row } from "reactstrap";
import { Observe } from "../../utils/mobx";

export function editPageRoute() {

}

interface Props {
    user: UserDetails,
    children: any,
    title: React.ReactNode,
    form: Form
}

export interface Form {
    submitting: boolean
    error?: string
    submit(): any
    canDelete?: () => Boolean
}

export function EditPageContainer({ user, children, title, form }: Props) {
    return <MainLayout>
        <div className="container my-5">
            {title}

            <div className="row bg-white py-3">
                <div className="col-12 ">
                    {children}

                    <Row>
                        <div className="col-12 text-danger">
                            <Observe>
                                {() => form.error ? form.error : null}
                            </Observe>
                        </div>
                    </Row>

                    <Row className="row pt-3">
                        <ActionButtons form={form} user={user} />
                    </Row>

                </div>
            </div>
        </div>
    </MainLayout>
}

const ActionButtons = observer(function ({ form, user }) {
    return form.submitting ?
        <Col className="text-center"><Spinner size={24} className="mr-3" /></Col>
        :
        <Col className="text-right">
            <a href={"/members/" + user.id}
                className="col-auto btn btn-outline-primary rounded-0 mr-3"
                onClick={() => null}
            >
                Back
            </a>

            {form.canDelete && form.canDelete() ?
                <button type="submit"
                    disabled={form.submitting}
                    onClick={form.delete}
                    className="col-auto btn btn-danger rounded-0 text-white mr-3">
                    Delete
            </button> : null}
            <button type="submit"
                disabled={form.submitting}
                onClick={form.submit}
                className="col-auto btn btn-primary rounded-0 text-white">
                Save
            </button>
        </Col>
})

export function InputSection({ title, required = false }) {
    return <div className="row bg-white">
        <div className="col-12 pt-3 pb-2">
            <div className="d-flex flex-column">
                <div className="font-size-20 font-weight-light-bold">
                    {title} {required && <span className="text-secondary">*</span>}
                </div>
            </div>
        </div>
    </div>
}

export function InputLabel({ required = false, children }) {
    return <label className="font-size-18 font-weight-light-bold">
        {children}
        {required && <span className="text-secondary">*</span>}
    </label>
}


export function InputContainer({ iconName, children }) {
    return <div className="row">
        <div className="col-12 pb-2">
            <div className="mb-2 font-size-20 font-weight-light-bold">
                {children}
            </div>
        </div>
    </div>
}