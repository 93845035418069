import React from "react";

interface Props {
    children(params: {on: boolean, toggle(): void})
}

export default class Toggle extends React.Component<Props> {
    state = {
        on: false
    }

    toggle = () => {
        this.setState({
            on: !this.state.on
        })
    }

    render() {
        return this.props.children({
            on: this.state.on,
            toggle: this.toggle
        })
    }
}