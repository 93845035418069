import React from 'react';
import { observer } from "mobx-react";

interface WatchProps {
    children(): React.ReactNode
}

@observer
export class Observe extends React.Component<WatchProps> {
    render() {
        return this.props.children()
    }
}