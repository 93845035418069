import { DropdownToggle, Dropdown, DropdownMenu, DropdownItem } from "reactstrap";
import React from "react";
import Toggle from "./MemberPage/Toggle";

interface Props {
    label: string,
    currentValue?: string,
    choose(value: string): void,
    choices: { label: string, value: string }[],
    className?: string
}
export default function DropdownButton({
    label,
    currentValue,
    choose,
    choices,
    className=''
}: Props) {
    return <Toggle>
    {({ on, toggle }) => <Dropdown isOpen={on} toggle={toggle}>
        <DropdownToggle caret className={"rounded-0 " + className} >
            {label}
        </DropdownToggle>
        <DropdownMenu>
            {choices.map(({ value, label }) => <DropdownItem
                    key={value}
                    disabled={value == currentValue}
                    onClick={() => choose(value)}>
                    {label}
            </DropdownItem>)}
        </DropdownMenu>
    </Dropdown> }
    </Toggle>
}