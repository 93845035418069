import React from 'react'
import MainLayout from './layout/MainLayout'
import Jumbotron from './home/Jumbotron';
import { PageMeta } from '../App';

export const NotFound = () => <MainLayout>
    <Jumbotron />
    <div className="container my-5">
            <div className="row mb-5">
                <div className="col-12">
                    <h2>Page Not Found</h2>
                </div>
        </div>
    </div>
</MainLayout>

export function pageNotFound(): PageMeta {
    return {
        title: 'WeTogether | Not Found',
        status: 404,
        rootElement:  <NotFound />
    }
}

export const onRoute = pageNotFound;