import React from 'react';
import { Article } from "../../types";
import { SmartImage } from '../SmartImage';
import { Icon, ICON_KEYBOARD_ARROW_RIGHT } from '../Icons';

interface Props {
    article: Article
}

export default ({ article: {
    title,
    date,
    content,
    image,
    original_url
} }: Props) =>
    <div className="row">
        <div className="col-12 col-sm-6 col-lg-4 my-3">
            <SmartImage className="img-fluid w-100" image={image} />
        </div>
        <div className="col-12 col-sm-6 col-lg-8">
            <h2 className="font-size-18 font-weight-bold">{title}</h2>

            <p className="font-size-14">
                {content}
            </p>

            <div className="text-right text-uppercase">
                <a href={original_url} target="_blank" className="btn btn-light">
                    read more
                    <Icon name={ICON_KEYBOARD_ARROW_RIGHT} />
                </a>
            </div>
        </div>
    </div>