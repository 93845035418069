import React from 'react';
import { Icon } from './Icons';
import Field from '../utils/Field';
import { observer } from 'mobx-react';

interface Props {
    iconName?: string,
    iconColor?: string,
    iconSize?: number,
    inputPlaceHolder?: string,
    buttonStr?: string,
    type?: string,
    customClass?: string,
    onPressEnter?: () => void,
    field: Field,
    bgColor?: string,
    bgDark?: boolean,
    inputBorder?: boolean,
    inputId?: string,
    inputName?: string,
    inputValue?: string,
    autoComplete?: string,
    children?: any
}

export default observer<React.StatelessComponent<Props>>(({
    iconName,
    iconColor="#a5aaad",
    iconSize=24,
    inputPlaceHolder="",
    buttonStr = "",
    type="text",
    customClass = "",
    onPressEnter = () => null,
    field=new Field(),
    bgColor= "#ffffff",
    bgDark=false,
    inputBorder=true,
    inputId,
    inputName,
    inputValue,
    autoComplete,
    children,
}: Props) => {
    let errorMsg = field && field.error;
    let bind = field && field.bind() || {}

    return <div className={`input-group custom-input ${customClass}`}>
        {iconName && (
            <div className="input-group-prepend">
                <span className={`input-group-text
                    rounded-0
                    border-grey-3
                    ${inputBorder ? 'border-right-0' : 'border-0'}`}
                    style={{ backgroundColor: `${bgColor}` }}>
                    <Icon name={iconName} color={errorMsg ? '#dc3545' : iconColor}
                        width={'1.2em'}
                        height={'1.2em'} />
                </span>
            </div>)
        }

        <input
            type={type}
            {...inputValue ? { value: inputValue } : {}}
            id={inputId ? inputId : undefined}
            name={inputName ? inputName : undefined}
            className={`form-control
             rounded-0
             ${bgDark ? 'text-grey-3' : ''}
             ${inputBorder ? 'border-left-0' : 'border-left-0 border-right-0 border-top-0'}
             ${!inputBorder&&errorMsg ? 'border-danger' : 'border-grey-3'}
             ${errorMsg ? 'is-invalid' : ''}`}
            style={{ backgroundColor: `${bgColor}` }}
            placeholder={inputPlaceHolder}
            onKeyPress={capturePressEnter(onPressEnter)}
            autoComplete={autoComplete}
            {...bind} />

        {type == 'checkbox' || type == 'radio' ? <>
            <label htmlFor={inputId}>
                    {inputPlaceHolder}</label> <span></span>
        </> : null }

        {children}

        <div className="invalid-feedback text-right">
          {errorMsg}
        </div>
    </div>
})

export const InputError = observer(function({ field }: { field: {error?: string}}) {
    return field.error ? <div className="invalid-feedback text-right">
        {field.error}
    </div> : null;
})

function capturePressEnter(f) {
    return event => {
        if (event.keyCode === 13) {
            f(event)
        }
    }
}