import { UserDetails } from "../../types";
import visitorImg from '../../assets/image/member/visitor.png'
import memberImg from '../../assets/image/member/member.png'
import powerImg from '../../assets/image/member/power.png'

export function roleDisplay(role: UserDetails["role"]) {
    switch (role) {
        case 'member':
            return 'Member'
        case 'power_member':
            return 'Power Member'
        case 'admin':
            return 'Admin'
        case 'visitor':
            return 'Visotor'
        default:
            return role
    }
}

export function roleImg(role: UserDetails["role"]) {
	switch (role) {
			case 'member':
					return memberImg
			case 'power_member':
					return powerImg
			case 'visitor':
			default:
					return visitorImg
	}
}