import React from 'react';
import { ICON_EVENT, Icon } from './Icons';


export default ({
    iconName=ICON_EVENT,
    title='',
    iconColor = '#000',
    iconSize = "35",
    marginBottom=true,
    link = null as string | null,
    linkLabel = 'view all' as string | null | React.ReactNode,
    linkIcon = null as string | null
}) =>
    <div className={`row align-items-center border-bottom border-grey-3 pb-2 ${marginBottom ? 'mb-5' : ''}`}>
        <div className="col-auto mt-2">
        <svg fill={iconColor} width={iconSize} height={iconSize}>
            <use xlinkHref={'#' + iconName}></use>
        </svg>
        </div>
        <div className="col-auto
        font-weight-light-bold
        font-size-25
        pl-0">
            {title}
        </div>
        {link ? <div className="col-auto
            ml-auto
            text-uppercase
            font-weight-light-bold
            text-grey-5
            font-size-13
            d-none
            d-md-block"><a className="muted-link" href={link}>
                {linkIcon ? <Icon name={linkIcon} /> : null}
                {linkLabel}
            </a></div> : null}
    </div>
