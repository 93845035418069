import { User, Event } from "./types";

export function canEditEvent(target: Event, user: User | null) {
    return user ? (
        user.role === 'admin' ||
        (user.role === 'power_member' && target.organizer_id == user.id)
    ) : false;
}

export function canEditUser(target: { id: any }, user?: User | null) {
    return user ? (user.role === 'admin' ? true : target.id == user.id) : false
}

export function isSuperUser(user: User | null) {
    return user ? user.role === 'admin' : false;
}

export function canCreateEvent(user: User | null) {
    return user ? (user.role === 'admin' || user.role === 'power_member') : false;
}

export function canEditComment(user: User | null, comment: { user_id: any }) {
    return user && (user.role === 'admin' || user.id == comment.user_id) ? true : false
}
