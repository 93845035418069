import React from 'react';
import { Icon, ICON_SEARCH } from './Icons';

export default ({
    iconName=ICON_SEARCH,
    iconColor="#87898a",
    iconSize=24,
    inputPlaceHolder="",
    buttonStr = "",
    initialValue = "",
    onSubmit = (value: string) => { }
}) => {
    let input: HTMLInputElement | null = null;

    const onKeyDown = (e) => {
        if (e.keyCode == 13) {
            e.preventDefault()
            onSubmit(e.target.value)
        }
    }

    const onBtnClick = e => {
        onSubmit(input!.value)
    }

    return <div className="input-group bg-white custom-input">
        <div className="input-group-prepend">
        <span className="input-group-text
            rounded-0
            border-white
            bg-white
            p-2">
        <Icon name={iconName} width={iconSize} color={iconColor} height={iconSize} />
        </span>
        </div>
        <input
            type="text"
            className="form-control border-white"
            placeholder={inputPlaceHolder}
            ref={e => { input = e; if (input && initialValue) { input.value = initialValue } }}
            onKeyDown={onKeyDown}
        />
        <div className="input-group-append">
        <button
            onClick={onBtnClick}
            type="button"
            className={`btn
                btn-primary
                px-2
                px-md-3
                px-lg-5
                rounded-0
                text-white
                font-weight-light-bold
                font-size-14
                text-uppercase`}
            >{ buttonStr }</button>
        </div>
    </div>}