import { UploadResult } from "../server/upload";

export async function uploadImage(file: File): Promise<UploadResult> {
    let body = new FormData();
    body.append('image', file)
    try {
        let resp = await fetch(process.env.SERVER_URL + '/api/images/upload', { method: 'POST', body });
        return await resp.json();
    } catch (e) {
        console.error(e)
        return {
            ok: false,
            message: e.message
        }
    }
}