import { observable, action, computed, runInAction } from "mobx";
import { Validator, datePattern, timePattern } from "./validators";
import moment from 'moment';
import { debounce } from "./funtional";
import { uploadImage } from "./upload";
import { ImageUrl } from "../types";


interface ValidatedField {
    value: any;
    validate(): Promise<boolean>
    reset(): void
}


export default class Field implements ValidatedField {
    @observable value : string = '';
    @observable error: string | null = null;
    private autoValidate = false
    private validators: Validator[]
    private initialValue?: string;

    setValue(val: string) {
        this.value = val
    }

    getValue() {
        return this.value
    }

    constructor(initialValue: string | undefined | null, ...validators: Validator[])
    constructor(...validators: Validator[])
    constructor() {
        let args = Array.from(arguments)
        if (typeof arguments[0] !== 'function') {
            this.initialValue = args.shift();
        }
        this.validators = args
        this.reset()
    }

    onChange(event: any) {
        this.setValue(
            event.target.type === 'checkbox'
                ? event.target.checked
                : event.target.value
        )
        if (this.autoValidate) {
            this.validate()
        }
    }

    onBlur() {
        this.validate()
    }

    disabled() {
        return false
    }

    /**
     * Helps to bind to react inputs <input {... field.bind()}
     */
    bind = () => ({
        value: this.getValue(),
        onChange: this._onChange,
        onBlur: this._onBlur,
        disabled: this.disabled()
    })

    private _onChange = action((e) => this.onChange(e))
    private _onBlur = action((e) => this.onBlur())

    validate = debounce(200, async () => {
        this.autoValidate = true
        let {value} = this;
        for (let f of this.validators) {
            let result = f(value)
            if(result !== true) {
                this.error = result
                return false
            }
        }
        this.error = null;
        return true
    });

    @action reset() {
        this.value = this.initialValue || ''
    }
}

export class BooleanField implements ValidatedField {
    @observable value: boolean;

    constructor(private initialValue = false) {
        this.reset()
    }

    async validate() {
        return true
    }

    @action reset = () => {
        this.value = this.initialValue ? true : false;
    }

    @action toggle = () => {
        this.value = !this.value
    }

    bind = () => (
        {
            checked: this.value ? true : false,
            onChange: this.toggle
        }
    )
}

export class DateTimeField implements ValidatedField {
    date: Field;
    time: Field;

    constructor(...validators: Validator[]) {
        this.date = new Field(...validators, datePattern)
        this.time = new Field(...validators, timePattern)
    }

    @computed get value() {
        return moment(this.date.value + ' ' + this.time.value).toISOString()
    }

    async validate() {
        return await this.date.validate() && await this.time.validate()
    }

    set value(value) {
        let dt = moment(value)
        this.date.value = dt.format('YYYY-MM-DD')
        this.time.value = dt.format('HH:mm')
    }

    @action reset() {
        this.date.reset()
        this.time.reset()
    }
}

export class ImageUploadField {
    @observable uploading = false;
    @observable value: ImageUrl | undefined;
    @observable error: string | null = null;

    constructor(private options: { required?: boolean, initialVaue?: ImageUrl }) {
        this.reset()
    }

    onChange = action(async (e: any) => {
        this.uploading = true;
        try {
            let result = await uploadImage(e.target.files[0]);
            this.uploading = false;
            if (result.ok === true) {
                this.value = result.image
            } else {
                this.error = result.message
            }
        } finally {
            this.uploading = false;
        }
    })

    async validate() {
        if (this.options.required && !this.value) {
            this.error = 'this is required'
            return false
        } else {
            this.error = null
            return true
        }
    }

    bind = () => {
        return {
            onChange: this.onChange
        }
    }

    reset() {
        this.value = this.options.initialVaue
    }
}

/**
 * Return values if all fields validate, null otherwise
 * @param fieldset
 */
export async function getValues<T extends { [key: string]: ValidatedField }>(fieldset: T): Promise<null | { [K in keyof T]: any }> {
    let keys = Object.keys(fieldset)
    let validAll = await Promise.all(
        keys.map(key => fieldset[key].validate().then((value) => {
            if ((fieldset[key] as Field).error) {
                console.log(key, value, (fieldset[key] as Field).error)
            }
            return value
        }))
    )
    if (validAll.indexOf(false) === -1) {
        let values: any = {}
        for (let k of keys) {
            values[k] = fieldset[k].value
        }
        return values;
    }
    return null
}

export function assignValues<Fields>(fields: Fields, source: Partial<{ [key in keyof Fields]: any }>) {
    runInAction(() => {
        for (let key of Object.keys(fields)) {
            if (key in source) {
                fields[key].value = source[key]
            }
        }
    })
}
