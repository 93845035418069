import App, { PageMeta } from './App';
import matchRoute from './utils/matchRoute';
import { parseQs } from './utils/url';

export interface RouteHandler {
    (app: App, params: any): Promise<PageMeta> | PageMeta
}

interface RootModule {
    onRoute: RouteHandler
}

export const Routes: { [route: string]: () => Promise<RootModule> } = {
    '/': () => import('./components/home/HomePage'),
    '/about': () => import('./components/About'),
    '/terms': () => import('./components/Terms'),
    '/team': () => import('./components/team/TeamPage'),
    '/privacy': () => import('./components/Privacy'),
    '/refund': () => import('./components/Refund'),
    '/login/:provider': () => import('./components/OAuthReturn'),
    '/events': () => import('./components/events/EventListPage'),
    '/events/create': () => import('./components/events/EventEditPage'),
    '/events/:event_id#edit': () => import('./components/events/EventEditPage'),
    '/events/:event_id/manage': () => import('./components/events/AttendeeAdmin'),
    '/events/:event_id/cancel/:cancel_user_id/:cancel_token': () => import('./components/events/EventPage'),
    '/events/:event_id': () => import('./components/events/EventPage'),
    '/members/:user_id/events/:query': () => import('./components/MemberPage/MemberEvents'),
    '/members/:user_id/edit-about': () => import('./components/MemberPage/EditAbout'),
    '/members/:user_id/edit-statement': () => import('./components/MemberPage/EditStatement'),
    '/members/:user_id/edit-job/:index': () => import('./components/MemberPage/EditWork'),
    '/members/:user_id/edit-education/:index': () => import('./components/MemberPage/EditEducation'),
    '/members/:user_id': () => import('./components/MemberPage/Index'),
    '/search': () => import('./components/SearchPage'),
    '/kotlin-tw': () => import('./components/kotlin/KotlinPage'),
    '/login': () => import('./components/auth/TokenLoginPage'),
    '/contact': () => import('./components/static_pages/ContactUs')
}

export async function route(app: App, path: string, query?: string): Promise<PageMeta> {
    if (path.indexOf('?') !== -1) {
        query = path.split('?')[1];
        path = path.split('?')[0];
    }
    let { route, params } = matchRoute(Routes, path);
    let module: RootModule = await (route ? Routes[route]() : import('./components/NotFound'));
    if (query) {
        params = { ...params, ...parseQs(query) }
    }
    return module.onRoute(app, params);
}