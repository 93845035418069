import React, { CSSProperties } from 'react'
import Header from './Header'
import { IconDefs } from '../Icons'
import Footer from '../home/Footer'
import Login from '../auth/Login'
import Register from '../auth/Register'
import ForgotPassword from '../auth/ForgotPassword'
import App from '../../App';
import Spinner from '../Spinner';
import Logout from '../Logout';

export default (({ children }) =>
    <>
        <IconDefs />
        <Header />
        <main className="main-view">
            {children}
        </main>
        <Footer />
        <ActiveModal />
        <WindowLoadingOverlay />
    </>
)

const ActiveModal = App.wrap(({ app }) =>
    app.activeModal == 'login' ?
        <Login />
        : app.activeModal == 'register' ?
            <Register />
            : app.activeModal == 'forgot_password' ?
                <ForgotPassword />
                : app.activeModal == 'logout' ?
                    <Logout />
                    : null
)

const WindowLoadingOverlay = App.wrap(({ app }) => {
    let height = (typeof window !== 'undefined') ? window.screen.height : 0;
    let css: CSSProperties = {
        position: 'fixed',
        top: app.loading ? '0px' : '-9999px',
        left: '0px',
        width: '100%',
        backgroundColor: 'rgba(120, 120, 120, 0.6)',
        zIndex: 1000,
        paddingTop: (height / 4) + 'px',
        height: height + 'px'
    }
    return <div style={css}>
        <Spinner />
        {typeof app.loading == 'string' ?
            <p className="text-white font-size-20 text-center mt-4 font-weight-light-bold">
                <em>{app.loading}</em>
            </p>
            : null}
    </div>
})