import React from 'react';
import { observer } from "mobx-react";

interface BoundSelect extends React.SelectHTMLAttributes<HTMLSelectElement> {
    field: {
        bind(): Partial<React.SelectHTMLAttributes<HTMLSelectElement>>
        error?: string | null
    }
}

export const YearSelect = observer(function ({ field, ...props }: BoundSelect) {
    return <select {...field.bind()} {...props}
        className={`form-control rounded-0 ${field.error ? 'is-invalid' : ''}`}>
        <option></option>
        {[... new Array(50).keys()].map(i =>
            <option key={i} value={new Date().getFullYear() - i}>
                {`${2018 - i}`}
            </option>)}
    </select>
})

export const MonthSelect = observer(function ({ field, ...props }: BoundSelect) {
    return <select {...field.bind()} {...props}
        className={`form-control rounded-0 ${field.error ? 'is-invalid' : ''}`}>
        <option></option>
        <option value="01">January</option>
        <option value="02">February</option>
        <option value="03">March</option>
        <option value="04">April</option>
        <option value="05">May</option>
        <option value="06">June</option>
        <option value="07">July</option>
        <option value="08">August</option>
        <option value="09">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
    </select>
})