
export enum PayMethod {
    free = 'free',
    admin = 'admin',
    paypal = 'paypal',
    paypalManual = 'paypal-mmanual',
    cash = 'cash',
    bankTransfer = 'bank-transfer',
    creditCard = 'credit-card'
}

export interface User {
    id: number
    role: 'visitor' | 'member' | 'power_member' | 'admin' | "system"
    name: string
    image?: ImageUrl
}

export interface UserDetails extends User {
    password_hash?: string
    email?: string
    about?: string
    skills?: string[]
    work_experience?: WorkExperience[]
    education?: Education[]
    interests?: string[]
    city?: string
    country?: string
    phone_number?: string
    linkedin_account?: string
    facebook_account?: string
    twitter_account?: string
    github_account?: string
    personal_statement?: string
    address?: string
    linked_accounts?: {
        google?: {
            id: string
        },
        facebook?: {
            id: string
        },
        linkedin?: {
            id: string
        }
    }
}

export interface Location {
    name: string
    address: string
    map_url: string
}

export interface NewEvent {
    status?: string
    organizer_id: number
    tags?: string[]
    notice?: string
    title: string
    subtitle: string
    date_from: string
    date_to: string
    location_name: string
    location_address: string;
    capacity: number
    image?: ImageUrl | null,
    description_html: string
    is_free_event: boolean,
    join_fee_amount: any,
    join_fee_currency: any,
    paypal_enabled: boolean,
    custom_paypal_link_enabled: boolean,
    custom_paypal_link: string,
    cash_enabled: boolean,
    credit_card_enabled: boolean,
    bank_transfer_enabled: boolean,
    bank_transfer_bank_code: string,
    bank_transfer_account_number: string,
    published: boolean,
    cancelled: boolean
}

export interface Event extends NewEvent {
    id: number,
    attendees_count?: number
}

export interface EventDetails extends Event {
    attendees: User[]
    organizer: User
}

export interface WorkExperience {
    company: string;
    title: string;
    date_from: string;
    date_to?: string;
    about: string
    current: boolean,
    location?: string
}

export interface Education {
    title: string
    school_name: string,
    date_from: string
    date_to?: string,
    location?: string,
    current: boolean
}

export type ParticipantEntry = ParticipantEntryConfirmed | ParticipantEntryCancellation;

export interface ParticipantEntryConfirmed {
    time: number,
    type: 'confirmed',
    event_id: number,
    user_id: number,
    payment_method: PayMethod | null,
    payment_data: any | null,
    cancellation_token?: any,
    actioned_by: {
        user_id: number,
        name: string
    }
}

export interface ParticipantEntryCancellation {
    time: number,
    type: 'cancelled',
    event_id: number,
    user_id: number,
    payment_method: PayMethod | null,
    actioned_by: {
        user_id: number,
        name: string
    }
}

export interface ParticipantEntryLog {
    user: UserDetails,
    log: ParticipantEntry[]
}

export type Notice = {
    color: 'danger' | 'info'
    message: string
}

export type ImageUrl = {
    xs?: string,
    s: string,
    m?: string,
    l?: string
}

export type OAuthProvider = 'google' | 'facebook' | 'linkedin';

export interface Comment {
    thread_id: string
    id: string
    time_posted: string
    time_received: string
    user_id: any
    auth_user_id: any
    message: string
    reply_to: null | string // comment id
    is_spam: boolean
    user?: User
    history: {
        message: string
        time_received: string
        auth_user_id: string
    }[]
}

export interface Article {
    id: string
    date: string
    title: string
    content: string
    image: ImageUrl
    original_url: string
}