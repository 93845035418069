import React, { FormEvent } from 'react';
import { observer } from 'mobx-react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import { isRequired } from '../../utils/validators';
import { observable } from 'mobx';
import Field from '../../utils/Field';
import App from '../../App';
import Input from '../Input';
import { ICON_MAIL } from '../Icons';


export default App.wrap(({app}) =>
    <Modal id="modalLogin" centered isOpen={true} toggle={app.closeModal}>
        <ModalHeader toggle={app.closeModal}>
            <div onClick={app.showLogin}
                    className="text-green-light
                        font-weight-light-bold
                        font-size-16
                        login-link
                        cursor-pointer">Login</div>
            Forgot password
        </ModalHeader>
        <ModalBody>
            <div className="container-fluid">
                <div className="row font-weight-light font-size-12 text-white">
                    <div className="col-12 text-grey-5 font-size-16">
                        Send reset password email
                    </div>
                    <div className="col-12">
                        <ForgotPasswordForm app={app} />
                    </div>
                </div>
            </div>


        </ModalBody>
        <ModalFooter>
            <div className="modal-sub-title text-grey-5 font-size-16">
                No account yet?
                <a onClick={app.showRegister} className="text-green-light font-weight-light-bold ml-3 cursor-pointer">Register</a>
            </div>
        </ModalFooter>
    </Modal>
)

@observer
class ForgotPasswordForm extends React.Component<{ app: App }> {
    email = new Field(isRequired)
    @observable formError: string | null = null
    @observable sending = false

    render() {
        return <form className="row justify-content-md-center" onSubmit={this.submit}>
            <div className="col-12 mt-3">
                <Input
                    iconName={ ICON_MAIL }
                    iconColor="#87898a"
                    inputPlaceHolder="Email"
                    type="email"
                    field={ this.email }
                    errorMsg={ this.email.error? this.email.error:null }/>
            </div>
            <div className="col-12 col-md-6 mt-3">
                <button type="submit"
                    disabled={this.sending}
                    className="col-12
                        py-2
                        border-0
                        bg-primary
                        font-size-14
                        font-weight-light-bold
                        cursor-pointer">
                    Send
                </button>
            </div>
        </form>
    }

    submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.sending = true
        let result = await this.props.app.api.forgotPassword({
            email: this.email.value
        })
        this.sending = false
        if(result.ok) {
        } else {
        }
    }
}