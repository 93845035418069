import React, { FormEvent } from 'react'
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import ApiClient from '../../ApiClient';
import App from '../../App';
import Field, {getValues} from '../../utils/Field'
import {isRequired, isEmail, minLength, passwordMatch} from '../../utils/validators'
import Input from '../Input';
import { ICON_PERSON, ICON_MAIL, ICON_LOCK, ICON_FB_STR, ICON_GOOGLE } from '../Icons';
import SocialAuthButtons from './SocialAuthButtons';
import { ga } from '../../tracking';


export default App.wrap(({app}) =>
    <Modal id="modalLogin" centered isOpen={true} toggle={app.closeModal}>
        <ModalHeader toggle={app.closeModal}>
            <div onClick={app.showLogin}
                className="text-green-light
                    font-weight-light-bold
                    font-size-16
                    login-link
                    cursor-pointer">Login</div>
            Register
        </ModalHeader>
        <ModalBody>
            <div className="container-fluid">
                <div className="row font-weight-light font-size-12 text-white">
                    <div className="col-12">
                        <RegistrationForm app={app} />
                    </div>
                    <div className="col-12 text-grey-5 font-size-16 text-center mt-5 mb-3">
                        or Register with
                    </div>
                    <SocialAuthButtons app={app} />
                </div>
            </div>
        </ModalBody>
    </Modal>
)

@observer
class RegistrationForm extends React.Component<{app: App}> {
    api = ApiClient();

    name = new Field(isRequired, minLength(2))
    email = new Field(isRequired, isEmail)
    password = new Field(isRequired, minLength(6))
    passwordRepeat = new Field(passwordMatch(this.password))
    @observable formError: string | null = null
    @observable sending = false

    render() {
        return <form className="row justify-content-md-center" onSubmit={this.submit}>
            {this.formError ? <div className="col-12 col-md-6 text-danger">{this.formError}</div> : null}
            <div className="col-12 mt-3">
                <Input
                    inputId="registerID"
                    iconName={ ICON_PERSON }
                    iconColor="#87898a"
                    inputPlaceHolder="Name"
                    type="text"
                    field={ this.name }
                    errorMsg={ this.name.error? this.name.error:null }/>
            </div>
            <div className="col-12 mt-3">
                <Input
                    inputId="registerEmail"
                    iconName={ ICON_MAIL }
                    iconColor="#87898a"
                    inputPlaceHolder="Email"
                    type="email"
                    field={ this.email }
                    errorMsg={ this.email.error? this.email.error:null }/>
            </div>
            <div className="col-12 mt-3">
                <Input
                    inputId="registerPassword"
                    iconName={ ICON_LOCK }
                    iconColor="#87898a"
                    inputPlaceHolder="Password"
                    type="password"
                    field={ this.password }
                    errorMsg={ this.password.error? this.password.error:null }/>
            </div>
            <div className="col-12 mt-3">
                <Input
                    inputId="registerRePassword"
                    iconName={ ICON_LOCK }
                    iconColor="#87898a"
                    inputPlaceHolder="Repeat password"
                    type="password"
                    field={ this.passwordRepeat }
                    errorMsg={ this.passwordRepeat.error? this.passwordRepeat.error:null }/>
            </div>
            <div className="col-12 col-md-6 mt-3">
                <button type="submit"
                    disabled={this.sending}
                    className="col-12
                        py-2
                        border-0
                        bg-primary
                        font-size-14
                        font-weight-light-bold
                        cursor-pointer">
                    Create account
                </button>
            </div>
        </form>
    }

    submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        ga('send', 'event', 'form', 'submit', 'login')
        let {name, email, password, passwordRepeat} = this;
        let values = await getValues({
            name,
            email,
            password,
            passwordRepeat
        })
        if (values) {
            this.sending = true
            var { ok, token, message } = await this.api.register(values)
            if(ok && token) {
                var { ok, message } = await this.props.app.setToken(token);
                if (ok) {
                    ga('send', 'even', 'auth', 'register')
                    this.props.app.closeModal();
                    if (this.props.app.path === '/') {
                        this.props.app.setLocation('/members/' + this.props.app.user!.id)
                    }
                }
            }
            if (message) {
                this.formError = message
            }
            this.sending = false
        }
    }
}