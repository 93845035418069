import InputGroup from "../InputGroup";
import { ICON_MAIL } from "../Icons";
import React from "react";
import { ServerApiMethods } from "../../server/api";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import { isEmail } from "../../utils/validators";

@observer
export default class extends React.Component<{ api: ServerApiMethods }> {
    @observable success ?: boolean
    @observable error ?: string

    @action submit = async value => {
        if (!value) {
            this.error = 'No value was entered'
            return
        } else if(isEmail(value) != true) {
            this.error = 'Invalid email address'
            return
        }
        let resp = await this.props.api.subscribe({ email: value })
        if (resp.ok) {
            this.success = true
            this.error = undefined
        } else {
            this.error = resp.message
        }
    }

    render() {
        return <div>
            <InputGroup
            iconName={ICON_MAIL}
            inputPlaceHolder="Your email"
            buttonStr="subscribe"
            onSubmit={this.submit}
            />
            {this.error ? <div className="text-danger">{this.error}</div> : null}}
            {this.success ? <div className="text-white">Thank you for subscribing!</div> : null}
        </div>
    }
}