import React from 'react';
import { ICON_PHONE, ICON_MAIL, ICON_HOME, Icon, ICON_FB } from '../Icons';
import SubscribeNewsletter from './SubscribeNewsletter';
import App from '../../App';

export default App.wrap(({ app }) =>
<footer className="bg-dark-blue font-size-16 font-weight-light">
    <div className="container pt-5 pb-3">
    <div className="row">
        {/* About Us */}
        <div className="col-12 col-sm-6 col-lg-3 mb-lg-5 order-lg-1 info mb-3">
        <div className="d-flex flex-column">
            <div className="text-white
            text-capitalize
            font-weight-light-bold
            mb-4
            font-size-18">About Us</div>
            <div className="mb-3"><a href="/about" className="text-light-blue">What is WeTogether?</a></div>
            <div className="mb-3"><a href="/team" className="text-light-blue">Team</a></div>
        </div>
        </div>
        {/* Help */}
        <div className="col-12 col-sm-6 col-lg-3 order-lg-2 info mb-3">
        <div className="d-flex flex-column">
            <div className="text-white
            text-capitalize
            font-weight-light-bold
            mb-4
            font-size-18">Help</div>
            <div className="mb-3"><a href="/contact" className="text-light-blue">Contact Us</a></div>
        </div>
        </div>
        {/* Company Information */}
        <div className="col-12 col-lg-6 order-lg-4 info mb-3">
        <div className="d-flex flex-column">
            <div className="text-white
            text-capitalize
            font-weight-light-bold
            mb-4
            font-size-18">Company Information</div>
            <a href="mailto:services@wetogether.co" className="text-light-blue mb-3 d-flex align-items-sm-center">
            <Icon name={ICON_MAIL} />
            <span className="ml-2">services@wetogether.co</span></a>
            <a href="tel:+886227073985" className="text-light-blue mb-3 d-flex align-items-sm-center">
            <Icon name={ICON_PHONE} />
            <span className="ml-2">+886-2-2707-3985</span></a>
            <span className="text-light-blue mb-3 d-flex align-items-sm-center">
            <Icon name={ICON_HOME} />
            <span className="ml-2">
                {'10F, No. 2, Section 3, Civic Blvd, Zhongzheng District, Taipei 100, Taiwan'}
            </span></span>
        </div>
        </div>
        {/* Newsletter */}
        <div className="col-12 col-lg-6 order-lg-3 info mb-5">
        <div className="d-flex flex-column">
            <div className="text-white
                text-capitalize
                font-weight-light-bold
                mb-4
                font-size-18">Newsletter</div>
            <SubscribeNewsletter api={app.api} />

        </div>
        </div>
        {/* Follow Us */}
        <div className="col-12 col-lg-6 order-lg-5 info mb-4">
        <div className="d-flex flex-column">
            <div className="text-white
                text-capitalize
                font-weight-light-bold
                mb-4
                font-size-18">Follow Us</div>
            <div>
                <a href="https://www.facebook.com/WeTogetherapp" target="_blank" className="mb-3">
                <Icon name={ICON_FB} width={50} height={50} />
                </a>
            </div>
        </div>
        </div>

    </div>
    <div className="row font-size-14 text-primary  text-center">
        <div className="col-12">
        <hr className="border-white mb-1"/>
        </div>
        <div className="col-12 col-sm-auto">
        <a href="/terms"  className="text-primary">Terms of Use</a>
        </div>
        <div className="col-12 col-sm-auto">
        <a href="/privacy"  className="text-primary">Privacy Policy</a>
        </div>
        <div className="col-12 col-sm-auto ml-auto text-center">
        © 2018 WeTogether.co All Rights Reserved.
        </div>
    </div>
    </div>
</footer>)