import React from 'react';
import InputGroup from '../InputGroup';
import { ICON_SEARCH } from '../Icons';
import App from '../../App';

interface Props {
    query?: string
    minimized?: boolean
}

export default App.wrap<Props>(({ app, query, minimized}) =>
    <div className={"jumbotron jumbotron-fluid absolute-center-box " + (minimized ? "minimized" : '') }>
        <div className="container-fluid center-item bottom">
        <div className="row search-bar py-3 justify-content-center">
            <div className="col-10 col-sm-9 col-lg-8">
            <InputGroup
                iconName={ICON_SEARCH}
                iconColor="#a5aaad"
                inputPlaceHolder="Explore Events, People or Something Interests You"
                initialValue={query || ''}
                buttonStr="search"
                onSubmit={app.search}
                    ></InputGroup>
            </div>
        </div>
        </div>
    </div>)
