import { ServerApiMethods } from "./server/api";
import httpApi from "./server/HttpApi";
import { API_URL } from './config'

export default function ApiClient({
    apiUrl = API_URL,
    token = null as null | string
} = {}): ServerApiMethods & { token?: string, url: string } {
    let api: any = {
        token,
        url: apiUrl
    };

    for (let key of Object.keys(httpApi)) {
        let { method, path } = httpApi[key];
        api[key] = function (params) {
            params = { ...params }; // make a copy
            let headers = {};
            if (api.token) {
                headers['Authorization'] = `Bearer ${api.token}`
            }
            if (method == 'POST' || method == 'PUT') {
                headers['Content-Type'] = 'application/json'
            }

            // extract url parameters from `params` to url:
            let _path = path.replace(/:([\w_]+)/g, match => {
                let key = match.replace(':', '');
                let value = params[key];
                delete params[key];
                return value;
            });

            let body = method == 'POST' || method == 'PUT' ? JSON.stringify(params) : undefined;

            let url = apiUrl + _path
            let qs = method == 'GET' || method == 'DELETE' ? '?' + toQueryString(params) : '';

            return new Promise(resolve => {
                let timeout = setTimeout(() => {
                    resolve({
                        ok: false,
                        message: 'No connection to server'
                    })
                }, 15000)
                fetch(url + qs, { method, headers, body })
                    .then(async res => {
                        let data = await res.json()
                        clearTimeout(timeout)
                        if (data.stack) { console.error(data.stack) }
                        return data
                    })
                    .catch(e => {
                        console.error(e)
                        return {
                            ok: false,
                            message: e.message
                        }
                    })
                    .then(resp => {
                        setTimeout(() => resolve(resp), 0)
                    })
            })
        }
    }
    return api;
}

function toQueryString(params) {
    return Object.keys(params)
        .filter(key => params[key])
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&')
}