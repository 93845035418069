import React from 'react';
import App from '../App';
import { ICON_CHECK, Icon } from './Icons';
import { ModalHeader, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

export default App.wrap(({app}) =>
    <Modal id="modalLogout" centered isOpen={true} toggle={app.closeModal}>
        <ModalHeader toggle={app.closeModal}>
            <div onClick={app.showLogin}
                    className="text-green-light
                        font-weight-light-bold
                        font-size-16
                        login-link
                        cursor-pointer">Confirm</div>
        </ModalHeader>
        <ModalBody>
        <div className="container-fluid">
                <div className="row font-weight-light font-size-16">
                    <div className="col-12 text-center">
                {app.user
                ? <>
                    {app.user.name}, logging out?
                </>
                : <>
                    <Icon name={ICON_CHECK} size={50} />
                    <br />
                    Done!
                </>}
                    </div>
                </div>
                </div>
        </ModalBody>
        <ModalFooter>
            <Button onClick={app.closeModal} color="default">Close</Button>
            {app.user && <Button
                color="danger"
                onClick={app.logout}>Logout</Button>}
        </ModalFooter>
    </Modal>)