import ReactDOM from 'react-dom';
import React from 'react';
import AppContainer from './AppContainer';
import App from './App';
import ApiClient from './ApiClient';
import windowEvents from './windowEvents';

export const STATE_KEY = '__APP_STATE';

export async function renderToWindow(window: Window & { app?: App }) {
    let root = window.document.getElementById('reactRoot')
    if (!root) {
        root = window.document.createElement('div');
        root.id = 'reactRoot';
        window.document.body.appendChild(root);
    }
    let state = window[STATE_KEY];

    const app = window.app = new App(
        window.document.location.pathname + window.document.location.search,
        ApiClient(),
        window[STATE_KEY],
    )

    if (document.location.hash) {
        setTimeout(() => {
            app.setLocation(
                window.document.location.pathname
                + window.document.location.hash
                + window.document.location.search
            )
        }, 100)
    }

    if (state) {
        await window.app.ready;
        ReactDOM.render(
            <AppContainer window={window} app={window.app} />,
            root
        )
    } else {
        ReactDOM.render(
            <AppContainer window={window} app={window.app} />,
            root
        )
    }
}

if (typeof window !== 'undefined' && window.document) {
    renderToWindow(window);
    windowEvents(window);
    if (process.env.NODE_ENV !== 'production') {
        import('./hmr')
    }
}
