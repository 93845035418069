import React from "react";
import SubTitle from "../../SubTitle";
import { ICON_ACCOUNT, ICON_COMMENT, ICON_PENCIL, ICON_BRUSH, ICON_WORK, ICON_SCHOOL } from "../../Icons";

export const AboutMeTitle = ({ editLink=null as null | string }) => <SubTitle
    iconName={ICON_ACCOUNT}
    iconColor="#f5658f"
    marginBottom={false}
    title="About Me"
    link={editLink}
    linkLabel={'edit'}
    linkIcon={ICON_PENCIL}
    />

export const PersonalStatementTitle = () => <SubTitle
    iconName={ICON_COMMENT}
    iconColor="#3dbb69"
    title="Personal Statement"
    marginBottom={false} />

export const SkillsTitle = () => <SubTitle
    iconName={ICON_BRUSH}
    iconColor="#4c7aae"
    marginBottom={false}
    title="Skillset" />

export const WorkExperienceTitle = () => <SubTitle
    iconName={ICON_WORK}
    iconColor="#f3643f"
    marginBottom={false}
    title="Work Experience" />

export const EducationTitle = () => <SubTitle
    iconName={ICON_SCHOOL}
    iconColor="#54ceff"
    title="Education" />