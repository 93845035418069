import React, { CSSProperties } from "react";

export default function Spinner({className='', width='', height='', size=70, fullWidth=false}) {
    className += ' text-center'
    width = width || (size + 'px');
    height = height || (size + 'px');
    return <div className={className} style={fullWidth ? { width: '100%' } : {}}>
        <div className='spinner2' style={{width, height}} />
    </div>

    // return <div className={className} {...restProps}>
    //     <div className="bounce1" style={{width, height}}></div>
    //     <div className="bounce2" style={{width, height}}></div>
    //     <div className="bounce3" style={{width, height}}></div>
    // </div>
}

export const SpinnerOverlay = (({ text='' }) => {
    let css: CSSProperties = {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.6)'
    }
    return <div style={css} ref={setOverlaySize} >
        <Spinner />
        {text ?
            <p className="text-white font-size-20 text-center mt-4 font-weight-light-bold">
                <em>{text}</em>
            </p>
            : null}
    </div>
})

function setOverlaySize(e: HTMLElement | null) {
    if (e) {
        e.style.height = e.parentElement!.clientHeight + 'px',
        e.style.width = e.parentElement!.clientWidth + 'px'
    }
}