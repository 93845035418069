import React, { FormEvent } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import ApiClient from '../../ApiClient'
import { observer } from 'mobx-react';
import { isRequired } from '../../utils/validators';
import { observable, action } from 'mobx';
import Field, { getValues } from '../../utils/Field';
import { ICON_MAIL, ICON_LOCK } from '../Icons';
import Input from '../Input';
import App from '../../App';
import SocialAuthButtons from './SocialAuthButtons';
import { ga } from '../../tracking';


export default App.wrap<{ error?: string }>(({app}) =>
    <Modal id="modalLogin" centered isOpen={true} toggle={app.closeModal}>
        <ModalHeader toggle={app.closeModal}>Login</ModalHeader>
        <ModalBody>
            <div className="container-fluid">
                <div className="row font-weight-light font-size-12">
                    <SocialAuthButtons app={app} />
                    <div className="col-12">
                        <LoginForm app={app}/>
                    </div>
                    <div className="col-12 font-size-16 text-center mt-4">
                        <a onClick={app.showForgotPassword} className="text-green-light font-weight-light-bold ml-3 cursor-pointer">
                            Forgot password?
                        </a>
                    </div>
                </div>
            </div>


        </ModalBody>
        <ModalFooter>
            <div className="modal-sub-title text-grey-5 font-size-16">
                No account yet?
                <a onClick={app.showRegister} className="text-green-light font-weight-light-bold ml-3 cursor-pointer">Register</a>
            </div>
        </ModalFooter>
    </Modal>
)


@observer
class LoginForm extends React.Component<{ app: App }> {
    api = ApiClient();

    email = new Field(isRequired)
    password = new Field(isRequired)
    @observable formError: string | null = this.props.app.lastAuthError
    @observable sending = false

    render() {
        return <form className="row justify-content-md-center" onSubmit={this.submit}>
            {this.formError ? <div className="col-12 px-3">
                <div className="alert-danger px-3 py-1 text-center font-size-16">
                    {this.formError}
                </div>
            </div> : null}
            <div className="col-12 mt-3">
                <Input
                    inputId="loginEmail"
                    iconName={ ICON_MAIL }
                    iconColor="#87898a"
                    inputPlaceHolder="Email"
                    type="email"
                    field={ this.email }
                    errorMsg={ this.email.error? this.email.error:null }/>
            </div>
            <div className="col-12 mt-3">
                <Input
                    inputId="loginPassword"
                    iconName={ ICON_LOCK }
                    iconColor="#87898a"
                    inputPlaceHolder="Password"
                    type="password"
                    field={ this.password }
                    errorMsg={ this.password.error? this.password.error:null }/>
            </div>
            <div className="col-12 col-md-6 mt-3">
                <button type="submit"
                    disabled={this.sending}
                    className="col-12
                        py-2
                        border-0
                        bg-primary
                        font-size-14
                        font-weight-light-bold
                        cursor-pointer">
                    Login
                </button>
            </div>
        </form>
    }

    @action submit = async (e: FormEvent<HTMLFormElement>) => {
        ga('send', 'event', 'form', 'submit', 'login')
        e.preventDefault();
        let { email, password } = this;
        let values = await getValues({ email, password })
        if (!values) { return; }
        this.sending = true;
        try {
            var { ok, token, message } = await this.api.login(values)
            this.formError = message || '';
            if (ok && token) {
                var { ok, message } = await this.props.app.setToken(token);
                if (ok) {
                    ga('send', 'event', 'auth', 'login', 'basic')
                    this.props.app.closeModal();
                }
            }
            this.sending = false
        } catch (e) {
            console.error(e)
            this.formError = e.message
        } finally {
            this.sending = false;
        }
    }
}