import React from "react";
import { ImageUrl } from "../types";

type Props = {
    image?: ImageUrl
    defaultSrc?: string
    width?: number
    height?: number
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

/**
 * Image component that will upgrade sto larger resolution
 * on bigger screen and will fallback to default image if
 * original fails to load.
 */
export class SmartImage extends React.Component<Props> {
    img: HTMLImageElement | null = null;
    state = {
        defaultSrc: this.props.image && this.props.image.s || this.props.defaultSrc || '',
        src: this.props.image && this.props.image.s || this.props.defaultSrc || ''
    }

    ref = (img: HTMLImageElement | null) => {
        if (img !== this.img) {
            this.img = img;
            if (img) {
                img.addEventListener('load', this.onLoad)
                img.addEventListener('error', this.onError)
            } else {
            }
        }
    }

    onLoad = () => {
        if (this.img) {
            let { width } = this.img.getBoundingClientRect();
            if (this.props.image) {
                let { s, m, l } = this.props.image;
                let src = width > 800 && l ? l : (width > 400 && m ? m : s);
                if (src != this.state.src) {
                    let prefetch = new Image;
                    prefetch.src = src
                    prefetch.addEventListener('load', () => {
                        this.setState({ src })
                    })
                }
            }
        }
    }

    onError = () => {
        if (this.state.src !== this.props.defaultSrc) {
            this.setState({src: this.props.defaultSrc})
        }
    }

    static getDerivedStateFromProps(props, state) {
        let intialSrc = props.image && props.image.s || props.defaultSrc || '';
        if(!state || state.intialSrc !== intialSrc) {
            return { intialSrc, src: intialSrc}
        }
        return null
    }

    render() {
        let { image, defaultSrc, ...props } = this.props;
        let src = this.state.src || this.props.defaultSrc;
        return src ? <img {...props} src={src} ref={this.ref} /> : null
    }
}