import { observable, action, runInAction } from "mobx";
import { UserDetails } from "../../types";
import App from "../../App";

export abstract class UserForm {
    protected app: App;
    protected user: UserDetails;
    @observable submitting = false;
    @observable error?: string;

    constructor(app: App, user: UserDetails) {
        this.app = app
        this.user = user
    }

    @action submit = async () => {
        this.submitting = true;
        if (await this.validate()) {
            await this.handleUpdate(this.getUpdateData());
        }
        this.submitting = false
    }

    async validate() {
        let formIsValid = true;
        for (let key of Object.keys(this)) {
            if (this[key] && this[key].validate) {
                let fieldIsValid = await this[key].validate();
                formIsValid = formIsValid && fieldIsValid;
            }
        }
        return formIsValid;
    }

    abstract getUpdateData(): Partial<UserDetails>

    async handleUpdate(data: Partial<UserDetails>) {
        let result = await this.app.api.updateUser({
            token: this.app.token,
            user: { ...data, id: this.user.id }
        })

        if (result.ok) {
            if (this.user.id == this.app.user!.id) {
                let userResponse = await this.app.api.getUserMe({ token: this.app.token })
                if (userResponse.ok) {
                    this.app.user = userResponse.user
                }
            }
            await this.app.setLocation('/members/' + this.user.id)
        } else {
            runInAction(() => {
                this.error = result.message;
                this.submitting = false;
            })
        }
    }

    canDelete() {
        return false
    }

    getDeleteUpdateData(): Partial<UserDetails> {
        return {}
    }

    @action delete = async () => {
        if (!this.canDelete()) { return }
        this.submitting = true;
        this.handleUpdate(this.getDeleteUpdateData())
    }
}