function matchRoute<T extends Object>(routes: T, path): { route: null | keyof T, params: {} }
function matchRoute(routes: string[], path): { route: null | string, params: {} } {
    if (!routes[0]) {
        routes = Object.keys(routes)
    }
    for (let route of routes) {
        let vars: string[] = [];
        let pattern = '^' +
            (route.replace(/(:[^/#]+)/g, m => {
                    vars.push(m.replace(':', ''))
                    return '([^/]+)'
                }))
            + '$';
        let match = new RegExp(pattern).exec(path);
        if (match) {
            let params = {}
            vars.forEach((key, i) => {
                params[key] = match![i + 1];
            })
            return {route, params}
        }
    }
    return { route: null, params: {}}
}

export default matchRoute;