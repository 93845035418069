import React from 'react';
import { observable, action, runInAction } from 'mobx';
import { observer } from "mobx-react";
import { UserDetails, Notice, Article, OAuthProvider } from './types';
import ApiClient from './ApiClient';
import { Event } from './types';
import { ServerApiMethods } from './server/api';
import { route } from './Router';
import { oauthLogin } from './server/auth/api/oauthLogin';
import { WEBAPP_URL } from './config';
import { ga } from './tracking';

interface OpenGraphMeta {
    title: string;
    image: string;
    type: string;
    description: string;
}

export interface PageMeta {
    title: string
    status?: number
    rootElement: React.ReactElement<any>
    run?: () => void,
    og?: OpenGraphMeta
}

const STORAGE_TOKEN_KEY = 'token';

export default class App {
    @observable path: string;
    @observable bootstraping = true;
    @observable activeSideMenu = false;
    @observable activeModal: 'login' | 'register' | 'forgot_password' | 'logout' | null = null;
    @observable user: UserDetails | null = null;
    @observable token: string;
    @observable title = 'WeTogether | Loading...';
    @observable status: number;
    @observable loading?: boolean | string = false;
    @observable upcomingEvents?: Event[];
    @observable kotlinEvents: Event[];
    @observable notices: Notice[] = [];
    @observable eventsAttending: Event[] = [];
    @observable lastAuthError = '';
    @observable latestArticles: Article[] = [];
    react = new ReactElements
    api: ServerApiMethods & { url: string };
    ready: Promise<void>;
    og?: OpenGraphMeta;

    constructor(path: string, api: ServerApiMethods & { url: string }, state: any = null) {
        if (state) {
            this.restoreState(state)
        }
        this.ready = this.setLocation(path);
        this.api = api;
        if (typeof window != 'undefined') {
            let token = window.localStorage.getItem(STORAGE_TOKEN_KEY);
            if (token) {
                this.token = token;
                this.api.getUserMe({ token })
                    .then(({ ok, user }) => {
                        if (ok && user) {
                            this.setUser(user, token!);
                        } else {
                            window.localStorage.removeItem(STORAGE_TOKEN_KEY);
                            this.token = '';
                        }
                    })
            }
        }
    }

    @action setLocation(path: string) {
        if (this.path && this.path != path) {
            this.activeModal = null;
            this.activeSideMenu = false;
            this.loading = true;
        }
        return route(this, path)
            .then(meta => {
                runInAction(() => {
                    if (typeof window != 'undefined') {
                        window.document.title = meta.title
                        if (!window.location.pathname.endsWith(path)) {
                            window.history.pushState({}, meta.title, path)
                        }
                        ga('set', 'page', path);
                        ga('send', 'pageview');
                    }
                    this.path = path;
                    this.bootstraping = false;
                    this.react.rootElement = meta.rootElement;
                    this.title = meta.title;
                    this.status = meta.status || 200;
                    this.loading = false;
                    this.og = meta.og || undefined;
                })
            })
    }

    goBack() {
        if (typeof window !== 'undefined') {
            window.history.go(-1)
        }
    }

    @action showLogin = () => {
        this.activeSideMenu = false;
        this.activeModal = 'login';
        ga('send', 'event', 'modal', 'open', 'login')
    }

    @action showRegister = () => {
        this.activeSideMenu = false;
        this.activeModal = 'register';
        ga('send', 'event', 'modal', 'open', 'register')
    }

    @action showLogout = () => {
        this.activeSideMenu = false;
        this.activeModal = 'logout';
        ga('send', 'event', 'modal', 'open', 'logout')
    }

    @action showForgotPassword = () => this.activeModal = 'forgot_password';

    @action closeModal = () => {
        if (this.activeModal) {
            ga('send', 'event', 'modal', 'close', this.activeModal)
        }
        this.lastAuthError = '';
        this.activeModal = null;
    }

    @action toggleSideMenu = () => this.activeSideMenu = !this.activeSideMenu;

    @action setUser = (user: UserDetails, token: string) => {
        this.user = user;
        this.token = token;
        this.api = ApiClient({ token });
        ga()
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(STORAGE_TOKEN_KEY, token);
        }
        this.api.getConfirmedEvents({ user_id: user.id })
            .then(({ events }) => {
                this.eventsAttending = events;
            })
    }

    @action setToken = async (token: string) => {
        var { ok, user, message } = await this.api.getUserMe({ token });
        if (ok && user) {
            this.setUser(user, token);
            if (typeof window !== undefined) {
                if (window.localStorage.getItem(STORAGE_TOKEN_KEY) != token) {
                    window.localStorage.setItem(STORAGE_TOKEN_KEY, token)
                }
            }
            return {
                ok: true as true
            }
        } else {
            return {
                ok: false as false,
                message: message as string | undefined
            }
        }
    }

    private async oauthLogin(provider: OAuthProvider) {
        let result = await this.api.getOauthLoginUrl({
            provider,
            state: this.createOauthState(),
            webappUrl: WEBAPP_URL
        })
        if (result.ok) {
            window.document.location.assign(result.url)
        } else {
            console.error(result)
            alert(result.message)
        }
    }

    facebookLogin = () => this.oauthLogin('facebook')
    googleLogin = () => this.oauthLogin('google')
    linkedinLogin = () => this.oauthLogin('linkedin')

    static wrap<P>(Component: React.SFC<P & { app: App }>): React.SFC<P> {
        const ReactiveComponent: any = observer(Component)
        return props => React.createElement(AppContext.Consumer, {
            children: (app: App) => React.createElement(ReactiveComponent, { app, ...props as any })
        })
    }

    // @action restoreState(data: ReturnType<App["exportState"]>) {
    //     var { cache , ...rest } = data;
    //     this.cache.restore(cache)
    //     Object.assign(this, rest)
    // }

    // exportState() {
    //     return {
    //         cache: this.cache.export()
    //     }
    // }

    saveState() {
        return {
            upcomingEvents: this.upcomingEvents,
            activeSideMenu: this.activeSideMenu,
            activeModal: this.activeModal,
            user: this.user,
            token: this.token
        }
    }

    @action restoreState(state) {
        Object.assign(this, state)
    }

    @action showErrorMessage(message: string) {
        alert(message)
    }

    createOauthState(): string {
        let scroll = typeof window !== 'undefined' ? window.scrollY : 0
        return `${this.path};${scroll}`
    }

    loadOauthState(state: string) {
        if (!state) {
            this.setLocation('/')
        }
        let [path, scroll] = decodeURIComponent(state).split(';')
        this.setLocation(path).then(() => {
            if (typeof window !== 'undefined') {
                window.scrollTo({ top: parseInt(scroll) })
            }
        })
    }

    @action logout = () => {
        ga('send', 'event', 'auth', 'logout')
        this.user = null;
        this.token = '';
        this.eventsAttending = [];
        this.api = ApiClient();
        this.activeSideMenu = false;
        if (typeof window !== 'undefined') {
            window.localStorage.clear()
        }
        this.setLocation('/')
    }

    @action search = (query) => {
        if (query) {
            this.setLocation('/search?q=' + encodeURIComponent(query))
        } else {
            this.setLocation('/')
        }
    }
}

class ReactElements {
    @observable rootElement: any;
}

export const AppContext = React.createContext<App>(new App('', ApiClient()));