import React from 'react';
import moment from 'moment';

import { Event } from '../../types';
import { Icon, ICON_TEAM } from '../Icons';
import App from '../../App';
import { SmartImage } from '../SmartImage';

export default App.wrap<{event: Event}>(function EventItem({ app, event }) {
    return (
        <div className="row">
            <div className={"col-12" + (event.published === false ? ' event-disabled' : '')}>
                <a className="h-100
                        col-12
                        px-0
                        event" href={"/events/"+event.id}>
                    <div className="img-box">
                        <SmartImage className="card-img-top rounded-0"
                            image={event.image}
                            defaultSrc={'/assets/images/kotlin/kotlin-default-cover.png'}
                            />
                    </div>
                    <div className="content p-3">
                        <h5 className="title mb-3 font-weight-bold font-size-16">{event.title}</h5>
                        <p className="description mb-2 font-size-14">{tirmStr(event.subtitle)}</p>
                        <div className="action-blcok">
                            <div className="d-flex info align-items-center text-grey-5 col-12 mb-3">
                                <Icon name={ICON_TEAM} color="#87898a" width={20} height={20} />
                                <span className="font-size-14 ml-2">{event.attendees_count}/{event.capacity}</span>
                                <span className="ml-auto font-size-14">{convertEventDate(event.date_from)}</span>
                            </div>
                            {
                            event.cancelled
                                ? <div className="join-btn py-2 w-100 text-uppercase font-weight-bold disabled">cancelled</div>
                                : app.eventsAttending.find(e => e.id == event.id)
                                ? <div className="join-btn py-2 w-100 font-weight-bold text-uppercase">attending</div>
                                    : isFull(event.attendees_count, event.capacity)
                                        ? <div className="join-btn py-2 w-100 text-uppercase font-weight-bold disabled">full</div>
                                        : <div className="join-btn py-2 w-100 font-weight-bold text-uppercase">join</div>
                            }
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
})

function convertEventDate(dataStr) {
    return moment(dataStr).format('MM/DD/YYYY')
}
function tirmStr(str) {
    const tmp_str= String(str).replace(/<[^>]+>/gm, '')
    if(tmp_str.length>50){
        return tmp_str.substring(0, 90)+'...'
    }
    return tmp_str
}
function isFull(attendeeCount, capacity) {
    return attendeeCount >= capacity
}
