import React from "react";
import menuIcon from "../../assets/image/icon/menu.svg";
import iconLogo from "../../assets/image/icon/logo.png";
import App from "../../App";
import { Icon, ICON_HOME, ICON_EVENT, ICON_BLOCK, rawIcon } from "../Icons";
import defaultUserImg from '../../assets/image/default/user-image.png';
import { canCreateEvent } from "../../acl";

export default App.wrap(({app}) =>
    <header
      className={`text-grey-5
        font-size-13 position-fixed w-100 bg-white
        ${app.activeSideMenu ? ' active': ''}`}
    >
      <HeaderContent />
      <Notices />
    </header>
);

const HeaderContent = App.wrap(({app}) =>
  <>
    <div className="container-fluid">
      <div className="row
        align-items-center
        absolute-center-box
        main-menu">
        <div className="col-auto pl-1 cursor-pointer"
          onClick={app.toggleSideMenu}>
          {rawIcon(menuIcon, { size: 44 })}
        </div>
        <div className="center-item
          center
          d-none
          d-lg-block">
          <a href="/" title="WeTogether">
            <img style={{width: '204px', height:'25px'}} src="/logo.svg" alt="WeTother Logo" />
          </a>
        </div>
        <div className="center-item center d-lg-none">
          <a href="/">
            <img src={iconLogo} />
          </a>
        </div>
        <div className="col-auto ml-auto" />
        <AuthMenu />
      </div>

    </div>
    <div className="side-menu position-fixed">
      <div className="mask w-100 h-100" onClick={app.toggleSideMenu} />
      <nav className="d-flex
        flex-column
        bg-white
        h-100
        position-fixed
        content
        font-size-15
        text-capitalize">
        <div className="bg-primary-darker
          py-2
          font-weight-light-bold
          font-size-20
          text-white
          px-3
          d-flex
          align-items-center">
          menu

          <svg
            fill="#FFFFFF"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
            className="ml-auto cursor-pointer"
            onClick={app.toggleSideMenu}
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
        <a href="/"  className="p-2
          d-flex
          pl-3
          align-items-center
          item
          muted-link">
          <Icon name={ICON_HOME} color="#eeb71c" />
          <div className="text-primary-darker ml-2">home</div>
        </a>
        <a href="/events" className="p-2
          d-flex
          pl-3
          align-items-center
          item
          muted-link">
          <Icon name={ICON_EVENT} color="#2799ab" />
          <div className="text-primary-darker ml-2">upcoming events</div>
        </a>

        {!app.user ?
          <>
            <div
              className="p-2
                d-flex
                pl-3
                align-items-center
                item"
                >
              <svg
                fill="#87898a"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6v-1z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
              <div className="ml-2">
                <a href="javascript:void(0)" onClick={app.showRegister} className="text-primary-darker">register</a>
              </div>
            </div>
            <div
              className="p-2
                d-flex
                pl-3
                align-items-center
                item"
                    >
              <svg
                fill="#87898a"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6v-1z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
              <div className="ml-2" >
                <a href="javascript:void(0)" onClick={app.showLogin} className="text-primary-darker">login</a>
              </div>
            </div>
          </>

          : <>
            <div className="p-2 d-flex pl-3 align-items-center item">
              <a href={`/members/${app.user.id}/events/joined`} className="ml-2 text-primary-darker">Joined Events</a>
            </div>

            {canCreateEvent(app.user) && <div className="p-2 d-flex pl-3 align-items-center item">
              <a href={`/members/${app.user.id}/events/hosted`} className="ml-2 text-primary-darker">Hosted Events</a>
            </div>}

            {canCreateEvent(app.user) && <div className="p-2 d-flex pl-3 align-items-center item">
              <a href="/events/create" className="ml-2 text-primary-darker">Create Event</a>
            </div>}

            <div className="p-2 d-flex pl-3 align-items-center item">
              <a href="javascript:void(0)" className="ml-2 text-primary-darker" onClick={app.showLogout}>Logout</a>
            </div>
          </>
        }
      </nav>
    </div>
  </>)

const AuthMenu = App.wrap(({app}) =>
  app.user ?
    (<div className="img-box mr-2">
      <a href={`/members/${app.user.id}`}>
        <img
           style={{ maxWidth: '100px' }}
           width={100}
          className="rounded-circle img-fluid"
          src={app.user.image && app.user.image.s || defaultUserImg} alt={app.user.name} />
      </a>
      </div>)  : <>
    <div
      className="col-auto
        text-uppercase
        font-weight-light-bold
        d-none
        d-md-block
        hover-underline-btn
        cursor-pointer"
      onClick={app.showRegister}
    >
      register
    </div>
    <div
      className="col-auto
        text-uppercase
        font-weight-light-bold
        hover-underline-btn
        cursor-pointer"
      ref="btnShow"
      onClick={app.showLogin}
    >
      login
    </div>
    </>)

const Notices = App.wrap(({ app }) => <>
  {app.notices.map((notice, index) =>
    <div key={index} className={'col-12 py-2 alert-' + notice.color} onClick={() => app.notices.splice(index, 1)}>
      {notice.message}
      <a className="float-right">
        <Icon name={ICON_BLOCK} />
      </a>
    </div>)}
  </>)