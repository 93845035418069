import React from 'react';
import EventItem from './EventItem';
import { Event } from '../../types';
import { sortBy } from '../../utils/funtional';
import Spinner from '../Spinner';

interface Props {
    events: Event[] | null | undefined
}

export default ({ events }: Props) => {
    return !events ? <Spinner /> : <div className="row">
        {events.length === 0 ? <div>
            No events
        </div> : null}
        {sortBy<Event>(events, "date_from", 'ASC').map(event =>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 " key={event.id}>
                <EventItem event={event} />
            </div>
        )}
    </div>
}