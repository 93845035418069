import { ServerApiMethods } from "./api";

type HttpApi = {
    [method in keyof ServerApiMethods]: {
        path: string,
        method: 'POST' | 'GET' | 'PUT' | 'DELETE'
    }
}

const httpApi: HttpApi = {
    register: { path: '/register', method: 'POST' },
    login: { path: '/auth/password-login', method: 'POST' },
    tokenLogin: { path: '/auth/token-login', method: 'POST' },
    forgotPassword: { path: '/auth/forgot-password', method: 'POST' },
    oauthLogin: { path: '/auth/oauth-code-login', method: 'POST' },
    getOauthLoginUrl: { path: '/auth/oauth-redirect-url', method: 'POST' },
    getUserMe: { path: '/users/me', method: 'GET' },
    usersSearch: { path: '/users/search', method: 'GET' },
    getAllUsersCsv: { path: '/users/export.csv', method: 'GET' },
    getUserByEmail: { path: '/users/find-by-email', method: 'POST' },
    getUser: { path: '/users/:id', method: 'GET' },
    getUserDetails: { path: '/users/:id/details', method: 'GET' },
    getUserStats: { path: '/users/:user_id/events/stats', method: 'GET' },
    getConfirmedEvents: { path: '/users/:user_id/events/joined', method: 'GET' },
    updateUser: { path: '/users/me', method: 'POST' },
    getParticipantStatus: { path: '/events/:event_id/entries/:user_id', method: 'GET' },
    createEvent: { path: '/events', method: 'POST' },
    queryEvents: { path: '/events', method: 'GET' },
    updateEvent: { path: '/events/:id', method: 'PUT' },
    getEventDetails: { path: '/events/:id', method: 'GET' },
    startPaypalPayment: { path: '/paypal/start', method: 'POST' },
    executePayment: { path: '/paypal/execute', method: 'POST' },
    subscribe: { path: '/subscriptions', method: 'POST' },
    getSubscribers: { path: '/subscriptions', method: 'GET' },
    getConfirmedUsers: { path: '/event-entries/confirmed-users', method: 'GET' },
    cancelParticipant: { path: '/event-entries/:user_id/cancel', method: 'POST' },
    getParticipantEntries: { path: '/event-entries/:event_id', method: 'GET' },
    getParticipantEntriesCsv: { path: '/event-entries/:event_id/export.csv', method: 'GET' },
    addParticipant: { path: '/event-entries/entries', method: 'POST' },
    search: { path: '/search', method: 'GET' },
    getLatestArticles: { path: '/articles/latest', method: 'GET' },
    getArticlesByTag: { path: '/articles/tag/:tag', method: 'GET' },
    putArticles: { path: '/articles', method: 'PUT' },
    createComment: { path: '/comments', method: 'POST' },
    getComments: { path: '/comments/:thread_id', method: 'GET' },
    putComment: { path: '/comments/:thread_id/:comment_id', method: 'PUT' },
    deleteComment: { path: '/comments/:thread_id/:id', method: 'DELETE' },
    sendMailToUser: { path: '/users/:id/sendmail', method: 'POST' },
    resizeImageFromUrl: { path: '/images/from-url', method: 'POST' },
    executeTapPayPayment: { path: '/tappay/execute', method: 'POST' }
}

export default httpApi