import Field from "./Field";

export type Validator = (value: string) => true | string;

export function isRequired(value) {
    return value ? true : 'this is required'
}

export function isRequiredIf(f: () => Boolean): Validator;
export function isRequiredIf(f: {value: any}): Validator;
export function isRequiredIf(f) {
    if (typeof f == 'function') {
        return value => {
            return f() ? isRequired(value) : true
        }
    } else {
        return value => {
            return f.value ? isRequired(value) : true
        }
    }
}

export function isEmail(value) {
    return value ? /^[^@\s]+@[\w_.-]+[.]\w+$/.test(value) || 'invalid email' : true
}

export function minLength(length: number) {
    return function(value) {
        return value ? value.length >= length || `at least ${length} characters` : true
    }
}

export function passwordMatch(field: Field) {
    return function(value) {
        return value === field.value || 'Does not match'
    }
}

export function positiveInt(value) {
    return value ? (parseInt(value) > 0 || 'Should be number') : true
}

export function pattern(regexp, message) {
    return function(value) {
        return value ? Boolean(value.match(regexp)) || message : true
    }
}

export const dateTimePattern = pattern(
    /^\d\d\d\d-\d\d-\d\d \d\d:\d\d$/,
    'Date and time in format: YYYY-MM-DD HH:mm'
)

export const datePattern = pattern(
    /^\d\d\d\d-\d\d-\d\d$/,
    'Date in: YYYY-MM-DD'
)

export const timePattern = pattern(
    /^\d\d:\d\d$/,
    'Time in: HH:mm'
)